import React from "react"
import TermsUk from "../../../components/body/pages/en-gb/termsUk"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

const TermsUkPage = () => (
  <Layout>
    <SEO
      canonical={"https://kuda.com/legal/uk/"}
      title="Terms & Conditions | Kuda | The Money App for Africans"
    />
    <TermsUk />
  </Layout>
)

export default TermsUkPage
